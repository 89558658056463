import React, { useState, useEffect, useRef, ReactNode } from "react"
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';
//import Stack from '@mui/material/Stack';
import { useAuthProfile } from "../../app/hooks"
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { IconButton, ListItemIcon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useLazyGetSearchResultsQuery, useGetFAQsQuery, useGetRecentSearchesQuery, useLazyGetSearchAutocompleteQuery } from '../../features/resourcesSlice'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Markdown from 'react-markdown'

import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Alert, Button, Box, Stack, Skeleton, ListItemButton, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
//import Badge from '@mui/material/Badge';
//import PersonIcon from '@mui/icons-material/Person';
import { debounce } from '@mui/material/utils';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { StartChat } from "../markers/MMMarkers";
import { useCreateOrJoinGroupChatMutation } from '../../features/ChatSlice'
import { useNavigate, useLocation } from "react-router-dom";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { MarkdownEmbeddedImageRenderer } from '../../app/blob-url-from-Base64'
import { Padding } from "@mui/icons-material";
import { PerplexitySearchResultsWithNavigation } from "./ResourceResults";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleIcon from '@mui/icons-material/Circle';
import { RenderStudies } from "./RenderStudies";


export function cleanText(text: string) {
  let validateText = text.replace(/[^a-zA-Z 0-9]+/g, '').trim()

  return { cleanText: text, validateText: validateText }
}

const ResourceTab: React.FC<TabProps> = (props) => (
  <Tab
    disableRipple
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      borderRadius: 4,
      marginRight: 1,
      marginLeft: 1,
      color: '#777777',
      backgroundColor: '#FAFAFA',
      '&:hover': {
        color: '#40a9ff',
      },
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#656EFF',
      },
    }}
    {...props}
  />
);

interface SearchText { cleanText: string, validateText: string }

export const ResourceLanding: React.FC = () => {
  let root = document.getElementById('root');
  root?.style.setProperty('position', 'static', 'important');
  root?.style.setProperty('width', '100%', 'important')

  // Initialization
  //const dispatch = useDispatch()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  //const { data: faqResult, isLoading: faqLoading } = useGetFAQsQuery()
  //const { data: recentSearchResult, isLoading: recentSearchResultLoading } = useGetRecentSearchesQuery()
  const [searchText, setSearchText] = useState<SearchText>({ cleanText: '', validateText: '' })
  //const [resultText, setResultText] = useState('')
  //const [getSearch, { data: searchResult, isLoading: isSearchLoading, isSuccess: searchSuccess, isError: searchError, isUninitialized: isSearchUninitialized }] = useLazyGetSearchResultsQuery()
  const [getAutocomplete, { data: autocompleteResult, isLoading: autocompleteLoading, isSuccess: autocompleteSuccess, isError: autocompleteError }] = useLazyGetSearchAutocompleteQuery()
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  //const [openResultPage, setOpenResultPage] = useState(false)
  //const [triggerSearch, setTriggerSearch] = useState(false)
  //const [faqTab, setFaqTab] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  //const hasSearchedFromURL = useRef(false);
  const navigate = useNavigate()

  const searchPage = searchParams.get("search")
  const category = searchParams.get('category');

  useEffect(() => {
    if (category !== '3') {
      if (searchPage) {
        setSearchText(cleanText(searchPage));
      }
    } else  setSearchText({ cleanText: '', validateText: '' });
  }, [searchPage])


  // Root function to handle dispatch of search
  function handleSubmit(e: any) {
    if (e)
      e.preventDefault();

    if (searchText.validateText.length === 0 || isSuccess === false) {
      return
    }
    // setLoading(true)
    // //setOpenResultPage(true)
    // //setSearchParams({ search: searchText.cleanText }, { replace: false })

    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);

    //getSearch({ query: searchText.cleanText, profile_id: profile.id }, true)// preferCacheValue: false // { forceRefetch: true })
    const queryParams = new URLSearchParams({
      search: searchText.cleanText,
      //category: category ?? ''
    });
    navigate(`/Resources?${queryParams.toString()}`);
    //setResultText(searchText.cleanText)
  }



  // manage debounce logic
  const debouncedSearch = React.useRef(
    debounce(async (text: string) => {
      getAutocomplete(text);
    }, 300)
  ).current;

  // Cancels debounce
  React.useEffect(() => {
    return () => {
      //debouncedSearch.cancel();
      debouncedSearch.clear(); // using MUI's debounce
    };
  }, [debouncedSearch]);

  // Handle FAQ search
  function handleFAQSearch(e: any, text: string) {
    document.getElementById('resource_top')?.scrollIntoView({ behavior: 'smooth' })
    // if (!loading) {
    //   setSearchText(cleanText(text))
    //   setTriggerSearch(true)
    //   setOpenResultPage(true)
    //   setShowAutocomplete(false)
    // }
    const queryParams = new URLSearchParams({
      search: text,
      category: category ?? ''
    });
    navigate(`/Resources?${queryParams.toString()}`);
  }

  //console.log(searchResult?.result )
  // Rendering
  return (
    <Container>
      <div id="resource_top"></div>
      <Stack sx={{ pt: 8, pb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', }} gap={2} >
        <Typography variant="h5">Knowledge Connect</Typography>
        {authStatus !== 'authenticated' &&
          <>
            <Link sx={{ cursor: 'pointer' }} variant="body1" onClick={() => navigate("/Sign-In")}>
              Sign in or create a free account to use search
            </Link>
            <Typography color="primary" variant="body1">
              Multiple Myeloma Patient Guide below is available to everyone
            </Typography>
          </>
        }

        <Paper
          elevation={0}
          sx={{ display: 'flex', flexGrow: 2, width: '100%', backgroundColor: '#F4F4F8', borderRadius: 8, pl: 2, justifyContent: 'space-between' }}
          component="form"
          onSubmit={handleSubmit}
        >
          {/*(category === '7') && <Typography variant="subtitle1" sx={{ padding: '10px', fontWeight: 'bold', pt: 1, color: '#62648F' }}>{searchText.cleanText}</Typography>*/}
          {/*(category !== '7') && */}<>
            <InputBase name="search" sx={{ flexGrow: 10 }} disabled={isSearchLoading || authStatus !== "authenticated"}
              placeholder="Ask any question"
              value={searchText.cleanText}
              inputProps={{ autoComplete: 'off' }}
              onChange={(e) => {
                if (e.target.value.replace(/[^a-zA-Z 0-9]+/g, '').trim().length > 0) {
                  setShowAutocomplete(true)
                  //debouncedSearch(e.target.value)
                  debouncedSearch(e.target.value)
                } else {
                  setShowAutocomplete(false)
                }
                setSearchText(cleanText(e.target.value));
                //setSearchParams({ name: 'Alice' }, { replace: false });

              }}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" >
              <ArrowCircleRightRoundedIcon sx={{ color: '#656EFF' }} />
            </IconButton>
          </>

        </Paper>
        {(autocompleteResult && showAutocomplete && autocompleteResult?.length > 0) &&
          <Box sx={{ width: '100%', backgroundColor: 'white', border: 1, borderColor: '#E0E0E0', maxHeight: '225px', overflow: 'auto' }}>
            <List>
              {autocompleteResult.map((row: any) => (
                <ListItem disablePadding key={row.search_term} >
                  <ListItemButton onClick={(e) => { handleFAQSearch(e, row.search_term) }} >
                    <ListItemText
                      primary={row.search_term}
                      primaryTypographyProps={{
                        fontSize: { xs: '0.75rem', sm: '0.95rem', md: '1rem' },
                        noWrap: true, // Ensures the text is limited to a single line
                        sx: {
                          overflow: 'hidden',       // Hide any overflowed text
                          textOverflow: 'ellipsis', // Add ellipses for overflowed text
                          whiteSpace: 'nowrap',     // Prevent text wrapping
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        {searchPage ? <PerplexitySearchResultsWithNavigation setLoading={(isLoading: boolean) => setIsSearchLoading(isLoading)} /> :
          <PatientGuide handleFAQ={handleFAQSearch} />}
      </Stack>
    </Container>
  )
}


// Component helpers
const ResourceTabs = styled(Tabs)({
  borderBottom: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});
interface ResourceTabProps {
  children: ReactNode; // Define children as ReactNode
  // Include any other props you might need here
}


export const PatientGuide: React.FC<{ handleFAQ: (e: any, text: string) => void }> = ({ handleFAQ }) => {
  const { data: faqResult, isSuccess: isFaqSuccess, isLoading: isFaqLoading, isError: isFaqError } = useGetFAQsQuery()
  const [faqTab, setFaqTab] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  // Access the current location (which contains the query string)
  const location = useLocation();
  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(location.search);
  // Example: Get a specific query parameter (e.g., `?name=John`)
  const category = queryParams.get('category');

  useEffect(() => {
    setFaqTab(Number(category ?? 0))
  }, [category, setFaqTab])

  // Handle faq tab change
  const handleChange = (event: any, newTab: number) => {
    setFaqTab(newTab);
    setSearchParams({ category: newTab.toString() }, { replace: false })
  };


  return (<>
    {(isFaqSuccess) &&
      <>
        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>Multiple Myeloma Patient Guide</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, maxWidth: '100%', border: 1, borderColor: '#E0E0E0' }}>
          <ResourceTabs
            value={faqTab}
            variant="scrollable"
            onChange={(event: any, newTab: number) => handleChange(event, newTab)}
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {faqResult.map((row: any) => (
              <ResourceTab label={row.category} key={row.category} />
            ))}
          </ResourceTabs>
        </Box>
        {category === '3' ? <RenderStudies questionsData={faqResult[3]} /> :
          <List sx={{ width: '100%', border: 1, borderColor: '#E0E0E0' }}>
            {faqResult[faqTab]['questions'].map((row: any) => (
              <ListItem disablePadding key={"item_" + faqTab.toString() + "_" + row}>
                <Stack direction="column" sx={{ width: '100%' }}>
                  <ListItemButton key={"button_" + faqTab.toString() + "_" + row} onClick={(e) => { handleFAQ(e, row) }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ListItemText
                      primary={row}
                      key={"text_" + faqTab.toString() + "_" + row}
                      primaryTypographyProps={{ fontSize: { xs: '0.75rem', sm: '0.85rem', md: '1rem' } }}
                    />
                    <ArrowOutwardIcon sx={{ color: '#777777', fontSize: 'medium' }} />
                  </ListItemButton>
                  <Divider sx={{ width: '99%', margin: 'auto' }} key={faqTab.toString() + "_" + row} />
                </Stack>
              </ListItem>

            ))}
          </List>
        }
      </>
    }
    {(isFaqLoading) &&
      <>
        <Skeleton animation="wave" variant="rectangular" width='50%' height={60} sx={{ border: 1, borderRadius: 8, borderColor: '#E0E0E0' }} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={400} />
      </>
    }
    {isFaqError &&
      <Alert severity={'error'}>'Oops... Sorry, there was a problem initialing Patient Connect. Please try again later</Alert>
    }
  </>)
}
