import React, { useState, useRef } from "react";
import { createContext, ReactElement, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Box, Button, Typography, Chip, Alert } from '@mui/material';
import { NavBar1 } from './components/navbar/NavBar1';
import { Map } from './components/map/Map';
import { Disclaimer, DataDeletionInstructions } from './components/Disclaimer';
import { LeftDrawer } from './components/LeftDrawer';
//import { Resources } from './components/resources/LinkGrid';
import Grid from '@mui/material/Grid';
import { MapBars } from './components/map/MapBars'
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { CircularProgress } from "@mui/material";
import { setSearch } from './features/searchSlice'
import { getDisclaimerCookie, setDisclaimerCookie } from './app/utils'
import { getUserAttributes } from './features/userAttributesSlice'
import { ProfileWizard } from './components/profile/ProfileWizard'

import { AddPinChip } from './components/profile/AddPinChip'
import { showDisclaimer, showProfile, showSurvey, showDeleteAccount, showDataDeletionInstructions } from "./features/dialogSlice";
import { useLocation } from "react-router-dom";
import { useAuthProfile } from "./app/hooks"
import { CenterMapHome } from "./components/map/CenterMapHome";
import { ChatMain } from "./components/messaging/ChatMain"
import { activateMarker } from "./features/activeMarkers"
// import { signInWithRedirect/*, deleteUser*/ } from 'aws-amplify/auth';
// import { showCareCenters, showWarriors } from './features/filterSlice';
// import { setPublicPinClicked } from './features/dataProcessingSlice';
import { printError } from './app/utils';
import { chatColors, SendbirdChatButton } from "./components/messaging/SendbirdChatButton"
//import {  useGetMessagingQuery } from './features/ChatSlice';
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { CircularProgressCentered } from "./app/sharedcomponents";
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useMediaQuery, useTheme } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { ConfirmDeleteProfile } from "./components/iam/DeleteProfile";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { RootState } from './app/store';


export const RightSideUIContext = createContext<any>({});

export const WrapSendBird: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { authStatus, authUser, userAttributesState, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady, messagingData, /*isSuccessMessaging: isSuccessMessaging*/ } = useAuthProfile()
  if (authStatus === 'configuring' || isLoading)
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      <CircularProgress />
    </div>)

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile ${printError(error)} error has occurred.`}
    </div>)
  }

  if (!isSuccess) {
    return null
  }

  if (messagingReady) {
    return (<>
      <SendbirdProvider appId={messagingData?.appId} userId={messagingData.userCreationResults.id?.toString()} accessToken={messagingData.userCreationResults.secret1} colorSet={chatColors}>
        {children}
      </SendbirdProvider> </>)
  }

  return (<>{children}</>)
}


export const App: React.FC<{ childComponent: React.ReactNode, deleteProfile: boolean, showProfileFromUrl: boolean, dataDeletionInstructions: boolean, messages: boolean, callback: boolean, out: boolean }> = ({ childComponent, deleteProfile, showProfileFromUrl, dataDeletionInstructions, messages, callback, out }) => {
  const { authStatus, authUser, userAttributesState, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady, messagingData, /*isSuccessMessaging: isSuccessMessaging*/ deleteTriggered } = useAuthProfile()
  //const [sendBirdReady, setSendBirdReady] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch()
  const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);

  React.useEffect(() => {
    if (callback) {
      console.log("callback auth status", authStatus)

      if (authStatus === 'unauthenticated') {
        console.log("Checking URL parms for re-logging in callback auth status", authStatus, "location", location)
        console.log("userAttributesState", userAttributesState)
        const searchParams = new URLSearchParams(location.search);
        console.log("searchParams", searchParams)
        searchParams.forEach((value, key) => {
          console.log(key, value);
        });
        if (searchParams.size === 0) {
          // try {            
          //   console.log("NO Url parms found - Re-logging in with FB")
          //   console.log("Profile", profile, "authUser", authUser, "userAttributesState", userAttributesState,)
          //   dispatch(setPublicPinClicked(false))
          //   dispatch(showCareCenters(false));
          //   dispatch(showWarriors(false));
          //   setDisclaimerCookie()
          //   dispatch(showDisclaimer(false));
          //   const user = signInWithRedirect({ provider: 'Facebook' })
          //   console.log("user", user)  
          // } catch (error) {
          //   // add error handling here
          //   console.log(error);
          // }
        } else {
          console.log("Url parms found - NOT relogging in")
        }
      }
    }
  }, [authStatus])

  if (callback) {
    console.log("CALLBACK")
  }
  //console.log('REACT_APP_CE_PRIVATE_KEY', process.env.REACT_APP_CE_PRIVATE_KEY)
  //console.log('REACT_APP_CE_PROJECT_ID',process.env.REACT_APP_CE_PROJECT_ID)

  // if (childName !== "AppAuth" || out) {
  //   //dispatch(setPublicPinClicked(false))   
  //   dispatch(activateMarker({ id: null, disableHover: false }))
  // }

  React.useEffect(() => {
    if (showProfileFromUrl)
      dispatch(showProfile({ isForced: true }))
  }, [showProfileFromUrl])

  React.useEffect(() => {
    if (deleteProfile)
      dispatch(showDeleteAccount(true))
  }, [deleteProfile])

  React.useEffect(() => {
    if (dataDeletionInstructions) {
      dispatch(showDataDeletionInstructions(true))
    }
  }, [dataDeletionInstructions])

  if (showDeleteAccountState) {
    return (<>
      <NavBar1 />
      <ConfirmDeleteProfile /></>)

  }



  if (authStatus === 'configuring' || isLoading)
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      <CircularProgress />
    </div>)

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile ${printError(error)} error has occurred.`}
    </div>)
  }
  if (!isSuccess) {
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile has not succeeded.`}
    </div>)
  }
  //NOT wrapping chatmain instead because of the open channel
  //   <WrapSendBird  >
  //   {childComponent ? <>{childComponent}</> : <AppBody />}
  // </WrapSendBird>
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: { xs: '100vh', sm: '100vh', md: '100vh' },
        //minHeight: '300vh',
        //backgroundColor: 'blue'
      }}>
      <RightSideUIContext.Provider value={{ linkData: location.state, deleteProfile: deleteProfile, messages: messages, callback: callback, messagingReady }}>
        <NavBar1 />
        <Box sx={{
          display: 'flex', width: '100%', //backgroundColor: 'red',
          flexGrow: 1,
          //height: { xs: '1500px', sm: '1500px', md: 'auto', lg: 'auto' }, // Set fixed height for xs screens, and auto height for md and lg screens
          // Allow Element 2 to grow on md and lg screens
        }}>
          <WrapSendBird  >
            {childComponent ? <>{childComponent}</> : <AppBody />}
          </WrapSendBird>

        </Box>
      </RightSideUIContext.Provider>
    </Box>
  );
}

const AppBody1: React.FC = () => {
  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.status) return true
    if (!next.status.isSuccess) return true
    if (!prev.center) return false
    return prev.formattedSearchTerm === next.formattedSearchTerm //&& ( prev.showProfileDialog === next.showProfileDialog  )
  });

  const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, isSuccessMessaging: isSuccessMessaging } = useAuthProfile()

  const showDisclaimerState = useSelector((state: any) => state.dialog.showDisclaimer)
  const showProfileState = useSelector((state: any) => state.dialog.showProfile)
  const showSurveyState = useSelector((state: any) => state.dialog.showSurvey)
  const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);
  const showProfileToComplete = profile ? profile.zipcode === null || profile.communicate_with_others === null || !profile.provide_information_for_research === null || !profile.receive_emails === null : false
  const isProfileVisible = (showProfileState || showProfileToComplete) && !showDeleteAccountState && authStatus === 'authenticated'
  const isSurveyVisible = (showSurveyState ? (!showSurveyState.isExited || showSurveyState.isForced || showSurveyState.isForcedFromSurvey) : false) && !showDeleteAccountState && authStatus === 'authenticated'
  const dispatch = useDispatch()
  const isDisclaimerCookie = getDisclaimerCookie()
  const { messages: ShowChatRightSideUI, sendBirdReady } = useContext(RightSideUIContext);
  const { showChat } = useSelector((state: any) => state.dialog.showDirectChat)
  //const [isBottomSheetReady, setIsBottomSheetReady] = useState(false);
  const [hasSnapped, setHasSnapped] = useState(false)
  const bottomSheetRef = useRef<BottomSheetRef | null>(null)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  //const searchStoryState: any = useSelector<any>(state => state.search);
  const lastgetSearchByStoryyStatusArgsAndData = useSelector((state: RootState) => {
    // Access all queries
    const queries = state.aiAnalytics.queries;
    // Find the last query key matching the "getPosts" endpoint
    const lastQueryKey = Object.keys(queries)
      .filter((key) => key.startsWith('getSearchByStory'))
      .pop();
    const argsString = lastQueryKey ? lastQueryKey.slice(lastQueryKey.indexOf('(') + 1, lastQueryKey.lastIndexOf(')')) : null;
    const args = argsString ? JSON.parse(argsString) : undefined;
    //   declare enum QueryStatus {
    //     uninitialized = "uninitialized",
    //     pending = "pending",
    //     fulfilled = "fulfilled",
    //     rejected = "rejected"
    // }
    const status: QueryStatus | undefined = lastQueryKey ? queries[lastQueryKey]?.status : undefined
    // Safely return data from the last query, if it exists
    return { status: status, args: args, data: lastQueryKey ? queries[lastQueryKey]?.data as any | undefined : undefined }
  });


  React.useEffect(() => {
    if (isSuccess) {
      //console.log(authStatus, profile.name_public)
      dispatch(setSearch({ lat: profile.lat, lng: profile.lng, formattedsearchterm: profile.formattedsearchterm }));
      //leocenter no need marker does it? dispatch(setCenter({ lat: profile.lat, lng: profile.lng })) 


      // problem here???
      //dispatch(activateMarker({ id: profile.iam_id, disableHover: true }))
      /*if (isDisclaimerCookie && isSuccess && authStatus === 'authenticated')// && isSurveyVisible)
        dispatch(showSurvey({ isForced: false }));*/
    }
  }, [profile])

  React.useEffect(() => {
    if (bottomSheetRef.current && bottomSheetRef.current && !hasSnapped) {
      // Delay the snapTo call to ensure the sheet is ready
      setTimeout(() => {
        if (bottomSheetRef.current) {
          bottomSheetRef.current.snapTo(window.innerHeight * 0.2);
          setHasSnapped(true)
        }
      }, 500); // Adjust the delay as needed
    }
  }, [bottomSheetRef.current, hasSnapped]); // needs both states to prevent infinite rendering

  React.useEffect(() => {
    if (bottomSheetRef.current && bottomSheetRef.current && lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.fulfilled
      && lastgetSearchByStoryyStatusArgsAndData?.data?.length > 0) {//searchStoryState.storySearch.complete) {
      if (bottomSheetRef.current) {
        bottomSheetRef.current.snapTo(window.innerHeight * 0.85);
      }
    }
  }, [lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.fulfilled]);

  if (isError) {
    const profileError = error.data ? error.data.error : error.message ?? ''

    if (profileError && profileError.includes("Key")) {
      //console.log("Profile error contains 'Key'.");
    } else {
      //console.log("Profile error does not contain 'Key' or is null.");
    }
    const keyVialoation = profileError && profileError.includes("Key")
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
      <Box>
        {!keyVialoation && <Typography> Profile {profileError} </Typography>}
        {keyVialoation && <Typography> Invalid Profile:  removed profile before creating a new one
          <Button href="/map/Sign-In">Login</Button></Typography>}
      </Box>
    </div>)
  }
  if ((!isSuccess && !isError) || !profile || searchState.status.isUnInitialized || (!userAttributesStateStatus.isSuccess && authStatus !== 'unauthenticated'))
    return (<>AppBody1<CircularProgressCentered /></>)

  return (
    (ShowChatRightSideUI) ?
      (isSuccessMessaging || authStatus === 'unauthenticated') ? <ChatMain /> : <Alert severity="error">Oops... Error occurred while initiating messaging feature.  Please try again later.</Alert>
      :
      <>
        <Grid container sx={{
          width: '100%',
          //flexGrow: 1,
          //height:  '100%',
          height: '100%', // Set different heights for different screen sizes
          //flexGrow: { xs: 0, sm: 0, md: 1 }, // Set different flex grow values for different screen sizes
          //backgroundColor: 'purple' 
        }}  >
          <Grid item xs={0} sm={0} md={5} lg={4} xl={4} sx={{ flexGrow: 1 }}>
            {/*<Resources />*/}
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, height: '100%', width: '100%' }}>
              <LeftDrawer />
            </Box>
            <BottomSheet
              id="bottomsheet"
              open={isSmallScreen}
              blocking={false}
              scrollLocking={false}
              expandOnContentDrag={true}
              defaultSnap={({ maxHeight }) => {
                if (!hasSnapped) {
                  return maxHeight * 0.85
                } else {
                  return maxHeight * 0.2
                }
              }}
              skipInitialTransition={true}
              snapPoints={({ maxHeight }) => [
                maxHeight * 0.85,
                maxHeight * 0.2,
                maxHeight * 0.1,
              ]}
              ref={(ref) => {
                bottomSheetRef.current = ref;
                if (ref !== null) {
                  //if( !isBottomSheetReady) setIsBottomSheetReady(true);
                }
              }}
            >
              <LeftDrawer bottomSheet={bottomSheetRef} />
            </BottomSheet>

          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={8} sx={{ position: 'relative' }}>
            <Box sx={{
              width: '100%',
              display: 'flex',
              position: { xs: 'fixed', sm: 'fixed', md: 'static', lg: 'static' },
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              //backgroundColor: 'red',
              height: '100%',
              overflow: 'hidden'
            }}>
              {!showChat && <MapUI authStatus={authStatus} profile={profile} />}
              {(sendBirdReady && showChat) && <SendbirdChatButton />}

            </Box>
          </Grid>
        </Grid>
        {(!isDisclaimerCookie && showDisclaimerState) && <Disclaimer />}

        {(isDisclaimerCookie && showProfileState) && <ProfileWizard />}

        <DataDeletionInstructions />
      </>

  )

}

const MapUI1: React.FC<{ authStatus: any, profile: any }> = ({ authStatus, profile }) => {
  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.status) return true
    if (!next.status.isSuccess) return true
    if (!prev.center) return false
    return prev.formattedSearchTerm === next.formattedSearchTerm //&& ( prev.showProfileDialog === next.showProfileDialog  )
  });


  return (
    <>
      {(authStatus === "authenticated" && profile.is_completed) &&
        <Paper
          elevation={2}
          sx={{
            backgroundColor: 'white',
            position: {
              xs: 'absolute', // theme.breakpoints.up('xs')
              sm: 'absolute', // theme.breakpoints.up('sm')
              md: 'absolute', // theme.breakpoints.up('md')
            },
            zIndex: 1,
            top: {
              xs: '90px', // theme.breakpoints.up('xs')
              sm: '90px', // theme.breakpoints.up('sm')
              md: '90px', // theme.breakpoints.up('md')
            },
            right: {
              xs: '10px', // theme.breakpoints.up('xs')
              sm: '10px', // theme.breakpoints.up('sm')
              md: '10px',
            }
          }}
        >
          <Stack direction="column" gap={0}>
            <CenterMapHome profileLat={profile.lat} profileLng={profile.lng} id={profile.id} />
            <Divider sx={{ width: '100%' }} />
            <AddPinChip id="map_add_pin" chipText={'Add my Pin'} />
          </Stack>
        </Paper>
      }
      <MapBars id={profile.id}
        lat={searchState.center.lat}
        lng={searchState.center.lng}
        profileLat={profile.lat}
        profileLng={profile.lng}
        formattedSearchTerm={searchState.formattedSearchTerm}
        zipCode={searchState.zipCode}
        authStatus={authStatus} />
      <Map1 authStatus={authStatus} profile={profile} />
    </>)
}
const Map1 = React.memo(Map);
export const AppBody = React.memo(AppBody1);
const MapUI = React.memo(MapUI1);
//export default App;
